import React, { Component } from "react";
import "./circle.css";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Film from "./components/Film";
import Acteur from "./components/Acteur";
import AddFilm from "./components/AddFilm";
import TechnicalError from "./components/TechnicalError";
import Logout from "./components/Logout";
import Stats from "./components/Stats";

class App extends Component {

  
  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
    };

    this.handleloginClick = this.handleloginClick.bind(this);
  }

  async handleloginClick(event) {
    event.preventDefault();
    var formData = new FormData();
    formData.append("email", event.target.email.value);
    formData.append("password", event.target.password.value);

    fetch(process.env.REACT_APP_API_URL + "api/login", {
      method: "POST",
      body: formData,
    }).then(
      (res) => {
        let main = this;
        if (res.status === 401) {
          // L'utilisateur n'est pas autorisé à accèder à l'application.
          this.setState({ authenticated: false, error: "Unauthorized" });
        } else if (res.status === 200) {
          res.json().then(function (data) {
            sessionStorage.setItem("userToken", data.success.token);
            sessionStorage.setItem("userAuth", true);
            sessionStorage.setItem("userId", data.success.id);
            sessionStorage.setItem(
              "userAction",
              JSON.stringify(data.success.user_actions)
            );
            main.setState({ authenticated: true });
          });
        }
      },
      (error) => {
        this.setState({
          technicalError: true,
          error: error,
        });
      }
    );
  }

  render() {
    document.body.classList.remove("login-background");
    if (this.state.technicalError) {
      return <TechnicalError />;
    }

    if (!sessionStorage.getItem("userToken")) {
      document.body.classList.add("login-background");
      return (
        <div>
          <main role="main" className="container-sm">
            <div className="row login-row">
              <div className="col-md-3 col-sm-3 col-3"></div>
              <div className="col-md-6 col-sm-6 col-6 login-col-md-6 border">
                <h3 className="login-title">MyMDB</h3>
                <form onSubmit={this.handleloginClick}>
                  <div className="form-group">
                    <input
                      type="text"
                      name="email"
                      className="form-control-sm login-input"
                      placeholder="Username"
                      required="required"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      name="password"
                      className="form-control-sm login-input"
                      placeholder="Password"
                      required="required"
                    />
                  </div>

                  {this.state.error === "Unauthorized" ? (
                    <div className="alert alert-danger" role="alert">
                      login ou mot de passe incorrect
                    </div>
                  ) : null}
                  &nbsp;
                  <div className="">
                    <input
                      type="submit"
                      className="btn btn-primary btn-login position-relative top-50 start-50 translate-middle"
                      value="Se connecter"
                    />
                  </div>
                </form>
              </div>
              <div className="col-md-3 col-sm-3 col-3"></div>
            </div>
          </main>
        </div>
      );
    }

    return (
      <div className="App">
        <Router>
          <Navbar />
          <br />
          <br />
          <br />
          <br />
          <main role="main" className="container-sm">
            <Switch>
              <Route path="/film/:filmID" component={Film}></Route>
              <Route path="/acteur/:acteurID" component={Acteur}></Route>
              <Route path="/add-film/" component={AddFilm}></Route>
              <Route path="/stats/" component={Stats}></Route>
              <Route path="/logout/" component={Logout}></Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </main>
        </Router>
      </div>
    );
  }
}

export default App;
