import React, { Component } from "react";
//import Spinner from "./Spinner";
import Chart from "chart.js";

export default class Stats extends Component {
  filmsPlatforms = React.createRef();
  filmsReleaseYear = React.createRef();
  filmsNoteByReleaseYear = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      filmsPlatforms: [],
      filmsReleaseYear: [],
      filmsNoteByReleaseYear: [],
      technicalError: false,
      error: null
    };
  }

  async getStatsFilms(url, type, title, label, ref) {
    await fetch(process.env.REACT_APP_API_URL + "api/" + url, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          //chartRef = React.createRef();
          const chartData = this.json_to_stats(result);
          const myChartRef = ref.current.getContext("2d");
          const myChart = new Chart(myChartRef, {
            type: type,
            data: {
              labels: chartData[0],
              datasets: [
                {
                  label: label,
                  backgroundColor: chartData[2],
                  data: chartData[1], 
                  fill: false
                }
              ]
            },
            options: {
              legend: { display: false },
              title: {
                display: true,
                text: title
              }
            }
          });

          switch (url) {
            case 'stats-films-platforms':
              this.setState({
                filmsPlatforms: myChart,
              });
              break;
            case 'stats-films-release':
              this.setState({
                filmsReleaseYear: myChart,
              });
              break;
            default:
              break;
          }
        },
        (error) => {
          this.setState({
            technicalError: true,
            error: error,
          });
        }
      );
  }

  componentDidMount() {
    this.getStatsFilms('stats-films-release', 'doughnut', 'Films par année de sortie', 'Nombre de films', this.filmsReleaseYear);
    this.getStatsFilms('stats-films-platforms', 'doughnut', 'Films par plateformes', 'Nombre de films', this.filmsPlatforms);
    this.getStatsFilms('stats-films-note-release', 'line', 'Note moyenne des films par année de sortie', 'Note moyenne', this.filmsNoteByReleaseYear);
  }

  json_to_stats(dataJson) {
    const labels = [];
    const data = [];
    const colors = [];
    // eslint-disable-next-line array-callback-return
    dataJson.map((gh, i) => {
      labels.push(gh.label);
      data.push(gh.total);
      colors.push(this.getRandomColor());
    });
    return [labels, data, colors];
  }

  getRandomColor() {
    var hue = Math.floor(Math.random() * 360);
    var pastel = 'hsl(' + hue + ', 100%, 85.5%)';
    return pastel;
  }

  render() {
    return (
      <div className="">
        <h5>Statistiques films</h5>
        <hr className="hr-title"></hr>
        <div className="row">
          <div className="col">
            <canvas
              id="myChart"
              ref={this.filmsPlatforms}
            />
          </div>
          <div className="col">
            <canvas
              id="myChart"
              ref={this.filmsReleaseYear}
            />
          </div>
        </div>
        <div className="row">
        <canvas
              id="myChart"
              ref={this.filmsNoteByReleaseYear}
            />
        </div>
      </div>

    )
  }
}
