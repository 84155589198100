import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Filmographie from "./Filmographie";
import Realisation from "./Realisation";
import Search from "./Search";
import TechnicalError from "./TechnicalError";
import Spinner from "./Spinner";

class Acteur extends Component {
  constructor(props) {
    super(props);
    this.state = {
      acteurData: null,
      technicalError: false,
      error: null
    };
  }

  componentDidMount() {
    this.getActeur();
  }

  async getActeur() {
    const {
      match: { params }
    } = this.props;

    fetch(process.env.REACT_APP_API_URL + "api/actors/" + params.acteurID, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("userToken")
      }
    })
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            acteurData: result.data
          });
        },
        error => {
          this.setState({
            technicalError: true,
            error: error
          });
        }
      );
  }

  testArrayLength(arr) {
    if (arr.length === 0) {
      return false;
    }
    return true;
  }

  sortArrayByFilmDate(arr) {
    let array = arr;
    return array.sort((a, b) => b.film_date - a.film_date);
  }

  render() {
    if (this.state.technicalError) {
      return <TechnicalError />;
    }

    if (!sessionStorage.getItem("userToken")) {
      return <Redirect to="/" />;
    }

    if (!this.state.acteurData) {
      return <Spinner />;
    }

    let realisation = this.testArrayLength(this.state.acteurData.directors);
    let filmographie = this.testArrayLength(this.state.acteurData.roles);

    let sortedDirectors = this.sortArrayByFilmDate(
      this.state.acteurData.directors
    );
    let sortedRoles = this.sortArrayByFilmDate(this.state.acteurData.roles);

    return (
      <div>
        <Search type="acteur" />
        <div className="card mb-3 desc-bloc">
          <div className="row no-gutters">
            <div className="col-md-3">
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  "image-acteur/" +
                  this.state.acteurData.idimdb
                }
                className="img-mymdb position-relative top-50 start-50 translate-middle img-fluid shadow-lg"
                alt="..."
              />
            </div>
            <div className="col-md-9">
              <div className="card-body">
                <div className="row">
                  <div className="col-7">
                    <h5 className="card-title">{this.state.acteurData.nom}</h5>
                  </div>
                  <div className="col-5">
                    {realisation ? (
                      <span className="badge badge-pill float-right badge-danger">
                        Réalisateur
                      </span>
                    ) : null}
                    {filmographie ? (
                      <span className="badge badge-pill float-right badge-primary">
                        Acteur
                      </span>
                    ) : null}
                  </div>
                </div>

                <p className="card-text">
                  <a
                    href={
                      "https://www.imdb.com/name/" +
                      this.state.acteurData.idimdb
                    }
                    target="_blank"
                    without="true"
                    rel="noopener noreferrer"
                  >
                    {this.state.acteurData.idimdb}
                  </a>
                </p>
                <p className="card-text">
                  Né le {this.state.acteurData.datenaissance} à{" "}
                  {this.state.acteurData.villenaissance}{" "}
                </p>
                <p className="card-text text-justify">
                  {this.state.acteurData.bio}
                </p>
              </div>
            </div>
          </div>
        </div>
        {realisation ? (
          <div>
            <Realisation realisations={sortedDirectors} />
          </div>
        ) : (
          <br />
        )}
        {filmographie ? (
          <div>
            <Filmographie roles={sortedRoles} />
          </div>
        ) : (
          <br />
        )}
      </div>
    );
  }
}

export default Acteur;
