import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import TechnicalError from "./TechnicalError";

class Search extends Component {
  filterList(event) {
    let inputVal = event.target.value.toLowerCase();
    if (inputVal !== "") this.search(inputVal);
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  constructor(props) {
    super(props);
    this.state = {
      items: null,
      itemsLength: 0,
      technicalError: false,
      error: null
    };
  }

  async search(e) {
    fetch(
      process.env.REACT_APP_API_URL + "api/" + this.props.type + "-search/" + e,
      {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("userToken")
        }
      }
    )
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            items: result,
            itemsLength: Object.keys(result).length
          });
        },
        error => {
          this.setState({
            technicalError: true,
            error: error
          });
        }
      );
  }

  render() {
    if (this.state.technicalError) {
      return <TechnicalError />;
    }

    if (!sessionStorage.getItem("userToken")) {
      return <Redirect to="/" />;
    }

    let result;
    let resultLabel;
    let resultClass;
    if (this.state.itemsLength === 0) {
      result = false;
      resultLabel = " résultat";
      resultClass = "result-info-fail";
    } else {
      result = true;
      resultLabel = " résultats";
      resultClass = "result-info-success";
    }

    if (this.state.itemsLength === 1) resultLabel = " résultat";

    return (
      <div>
        <div className="input-group flex-nowrap shadow">
          <span className="input-group-text lib-search" id="addon-wrapping">Rechercher un {this.props.type}</span>
          <span className={"input-group-text " + resultClass} id="addon-wrapping">{this.state.itemsLength} {resultLabel}</span>
          <input type="text" onChange={this.filterList.bind(this)} className="form-control" placeholder={this.capitalizeFirstLetter(this.props.type)} aria-describedby="addon-wrapping"></input>
        </div>

        {result ? (
          <div>
            <ul className="list-group shadow">
              {this.state.items.map((gh, i) => (
                <li className="list-group-item list-group-item-p" key={i}>
                  <a href={"/" + this.props.type + "/" + gh.id}>{gh.nom}</a>
                </li>
              ))}
            </ul>
            <br />
          </div>
        ) : (
          <br />
        )}
      </div>
    );
  }
}

export default Search;
