import React, { Component } from "react";
import Spinner from "./Spinner";

class Accordian extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filmImages: null,
      technicalError: false,
      error: null
    };
  }

  componentDidMount() {
    this.getImages();
  }

  async getImages() {
    var formData = new FormData();
    formData.append("idimdb", this.props.idimdb);

    fetch(process.env.REACT_APP_API_URL + "api/get-film-images", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("userToken")
      }
    })
      .then(res => res.json())
      .then(
        result => {
          this.setState({
            filmImages: result
          });
        },
        error => {
          this.setState({
            technicalError: true,
            error: error
          });
        }
      );
  }

  render() {
    if (!this.state.filmImages) {
      return <Spinner />;
    }

    if (this.state.filmImages.length < 7) {
      return null;
    }

    return (

      <div>
          <div className="gallery-wrap">
          {this.state.filmImages.map((gh, i) => {
            if (i <= 6) {
              const imageUrl = "https://image.tmdb.org/t/p/w500/" + gh.file_path;
              return (
                <div key={i} className="item" style={{backgroundImage: `url(${imageUrl})` }}></div>
              );
            }
            return null;
          })}
        </div>
        <br/>
      </div>
    );
  }
}

export default Accordian;
