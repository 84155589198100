import React, { Component } from "react";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: null };
  }

  render() {
    return (
      <nav className="navbar navbar-dark fixed-top">
        <div className="container-fluid">
          <a className="navbar-brand text-white" href="/home">MyMDB</a>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span className="navbar-toggler-icon "></span>
          </button>
          <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">MyMDB</h5>
              <button type="button" className="btn-close btn-close-white text-reset text-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li className="nav-item">
                  <a className="nav-link text-white" href="/add-film"><i className="bi bi-plus-lg"></i>&nbsp;Ajouter un film</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link text-white" href="/stats"><i className="bi bi-bar-chart"></i>&nbsp;Statistiques</a>
                </li>
                <li className="nav-item">
                <a className="nav-link text-white" href="/logout"><i className="bi bi-power"></i>&nbsp;Se déconnecter</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
