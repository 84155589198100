import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import TechnicalError from "./TechnicalError";
import Search from "./Search";
import Spinner from "./Spinner";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filmsData: null,
      seriesData: null,
      technicalError: false,
    };
  }

  componentDidMount() {
    this.getFilmsData();
    this.getSeriesData();
  }

  async getFilmsData() {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "api/films-top-films",
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        }
      );
      if (response.status === 200) {
        const resp = await response.json();
        this.setState({ filmsData: resp });
      } else {
        this.setState({ technicalError: true });
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getSeriesData() {
    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "api/films-top-series",
        {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        }
      );
      if (response.status === 200) {
        const resp = await response.json();
        this.setState({ seriesData: resp });
      } else {
        this.setState({ technicalError: true });
      }
    } catch (err) {
      console.error(err);
    }
  }

  render() {
    if (this.state.technicalError) {
      return <TechnicalError />;
    }

    if (!sessionStorage.getItem("userToken")) {
      return <Redirect to="/" />;
    }

    if (!this.state.filmsData || !this.state.seriesData) {
      return <Spinner />;
    }

    return (
      <div className="container-sm">
        <Search type="acteur" />
        <Search type="film" />

        <div className="row">
          <div className="col-md-3"></div>
          <img
            src={process.env.PUBLIC_URL + "/img/top.jpg"}
            alt="login"
            id="login-img"
            className="col-md-6 img-fluid img-top"
          />
          <div className="col-md-3"></div>
        </div>

        <div className="row">
          <div className="wrapper">
            <h5>
              Top films <span>({this.state.filmsData.length})</span>
            </h5>
            <hr className="hr-title" />
            <div className="cards">
              {this.state.filmsData.map((gh, i) => (
                <a key={i} href={"/film/" + gh.id}>
                  <figure className="card-item">
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        "image-film/" +
                        gh.idimdb
                      }
                      alt={gh.titre}
                    />

                    <figcaption>{gh.titre}</figcaption>
                  </figure>
                </a>
              ))}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="wrapper">
            <h5>
              Top Série <span>({this.state.seriesData.length})</span>
            </h5>
            <hr className="hr-title" />
            <div className="cards">
              {this.state.seriesData.map((gh, i) => (
                <a key={i} href={"/film/" + gh.id}>
                  <figure className="card-item">
                    <img
                      src={
                        process.env.REACT_APP_API_URL +
                        "image-film/" +
                        gh.idimdb
                      }
                      alt={gh.titre}
                    />

                    <figcaption>{gh.titre}</figcaption>
                  </figure>
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
