import React, { Component } from "react";
import Spinner from "./Spinner";

class AddFilm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      traitementOK: null,
      traitementEnCours: false,
    };

    this.handleloginClick = this.handleloginClick.bind(this);
  }

  async handleloginClick(event) {
    event.preventDefault();
    var formData = new FormData();
    formData.append("idimdb", event.target.idimdb.value);
    formData.append("source", event.target.source.value);

    this.setState({ traitementEnCours: true });

    fetch(process.env.REACT_APP_API_URL + "api/add-film", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            traitementOK: true,
            traitementFilmID: result.film_id,
            traitementEnCours: false,
          });
        },
        (error) => {
          this.setState({
            traitementOK: false,
            traitementFilmID: 0,
            traitementEnCours: false,
          });
        }
      );
  }

  redirectFilm(id){
    window.location.href = '/film/'.id;
  }
  render() {
    if (this.state.traitementEnCours) {
      return <Spinner />;
    }

    if (!this.state.traitementOK) {
      return (
        <div>
          <div className="row">
            <div className="col-md-3"></div>
            <img
              src="/img/add-film.jpg"
              alt="login"
              id="login-img"
              className="col-md-6 img-fluid img-top"
            />
            <div className="col-md-3"></div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <form onSubmit={this.handleloginClick}>
                <div className="form-group">
                  <label htmlFor="idimdbInput">ID IMDB</label>
                  <input
                    type="text"
                    className="form-control"
                    id="idimdbInput"
                    name="idimdb"
                    placeholder="tt2911666"
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="sourceSelect">Source</label>
                  <select className="form-control" id="sourceSelect" name="source">
                    <option value="DD">Disque Dur</option>
                    <option value="NETFLIX">Netflix</option>
                    <option value="AMAZON">Amazon</option>
                    <option value="DISNEY">Disney+</option>
                    <option value="GOOGLE">Google Play</option>
                    <option value="CANAL">MyCanal</option>
                    <option value="ITUNES">Itunes</option>
                    <option value="DVD">DVD</option>
                    <option value="BR">Blue Ray</option>
                    <option value="PERDU">Perdu</option>
                  </select>
                </div>
                &nbsp;
                <div className="form-group">
                  <input
                    type="submit"
                    className="btn btn-primary"
                    value="Ajouter"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.traitementOK && (this.state.traitementFilmID !== 0)) {
      var myID = this.state.traitementFilmID
      var myRedirectFunc = function(){  window.location.href = '/film/' + myID; }
      setTimeout(myRedirectFunc , 5000);
      return (
        <div className="alert alert-success" role="alert">
          Ajout du film réalisé avec success
        </div>
      );
    } else {
      return (
        <div className="alert alert-danger" role="alert">
          Erreur rencontrée lors de l'ajout du film !!
        </div>
      );
    }
  }
}

export default AddFilm;
