import React, { Component } from "react";
import TechnicalError from "./TechnicalError";

class Seen extends Component {
  constructor(props) {
    super(props);
    this.state = { technicalError: false, userActions: null, error: null };

    this.handleUserActionClick = this.handleUserActionClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.setState({
      userActions: this.props.userActions
    });
  }

  async handleUserActionClick(type) {
    var formData = new FormData();
    formData.append("film_id", this.props.filmId);
    formData.append("user_id", sessionStorage.getItem("userId"));
    formData.append("type", type);

    fetch(process.env.REACT_APP_API_URL + "api/update-action-film", {
      method: "POST",
      body: formData,
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("userToken")
      }
    })
      .then(res => res.json())
      .then(
        result => {
          sessionStorage.removeItem("userAction");
          sessionStorage.setItem(
            "userAction",
            JSON.stringify(result.success.user_actions)
          );
          this.setState({
            userActions: JSON.stringify(result.success.user_actions)
          });
        },
        error => {
          this.setState({
            technicalError: true,
            error: error
          });
        }
      );
  }

  render() {
    if (this.state.technicalError) {
      return <TechnicalError />;
    }

    let userActions = JSON.parse(this.state.userActions);
    let action = userActions.find(
      element => element["film_id"] === this.props.filmId
    );

    if (!action) {
      return (

        <div class="row">
          <div class="col-md-6 offset-md-3">
            <button
                type="button"
                className="btn btn-danger btn-sm position-relative top-50 start-50 translate-middle"
                onClick={() => this.handleUserActionClick("insert")}
              >
                Pas vu
              </button>
          </div>
        </div>
        
      );
    } else {
      return (
        <div class="row">
          <div class="col-md-6 offset-md-3">
            <button
              type="button"
              className="btn btn-success btn-sm position-relative top-50 start-50 translate-middle"
              onClick={() => this.handleUserActionClick("update")}
            >
              Vu le {action.updated_at}
            </button>
          </div>
        </div>
      );
    }
  }
}

export default Seen;
