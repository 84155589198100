import React, { Component } from "react";

class Filmographie extends Component {
  constructor(props) {
    super(props);
    this.state = { show: true };
  }

  handleShowClick() {
    this.setState({ show: true });
  }

  handleHideClick() {
    this.setState({ show: false });
  }

  render() {
    let button;

    if (this.state.show) {
      button = (
        <button
          type="button"
          onClick={() => this.handleHideClick()}
          className="btn btn-danger btn-sm float-right"
        >
          Cacher
        </button>
      );
    } else {
      button = (
        <button
          type="button"
          onClick={() => this.handleShowClick()}
          className="btn btn-primary btn-sm float-right"
        >
          Afficher
        </button>
      );
    }

    return (
      <div>
        <br />
        <div className="row">
          <div className="col-10">
            <h5>Filmographie</h5>
          </div>
          <div className="col-2">{button}</div>
        </div>
        <br />
        {this.state.show ? (
          <table id="filmo-table" className="table table-hover border">
            <thead>
              <tr>
                <th scope="col">Film</th>
                <th scope="col">Rôle</th>
                <th scope="col">Date</th>
              </tr>
            </thead>
            <tbody>
              {this.props.roles.map((gh, i) => (
                <tr key={i}>
                  <td>
                    <a className="link-white" href={"/film/" + gh.film_id}>{gh.film_name}</a>
                  </td>
                  <td>{gh.role}</td>
                  <td>{gh.film_date}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <br />
        )}
      </div>
    );
  }
}

export default Filmographie;
