import React, { Component } from "react";

class TechnicalError extends Component {
  render() {
    return (
      <div class="row">
        <div class="col-md-12">
          <div class="error-template">
            <h1>Oops!</h1>
            <h2>Une erreur s'est produite</h2>
            <div class="error-actions">
              <a href="/" class="btn btn-primary btn-sm">
                <span class="glyphicon glyphicon-home"></span>
                Revenir à l'accueil
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TechnicalError;
