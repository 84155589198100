import React, { Component } from "react";

class Logout extends Component {
  UNSAFE_componentWillMount() {
    sessionStorage.clear();
    window.location.href = window.location.origin;
  }

  render() {
    return <div>Redirection</div>;
  }
}

export default Logout;
