import React, { Component } from "react";

class Episode extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  handleShowClick() {
    this.setState({ show: true });
  }

  handleHideClick() {
    this.setState({ show: false });
  }

  render() {
    if (this.props.episodes.length === 0) {
      return null;
    }

    let button;

    if (this.state.show) {
      button = (
        <button
          type="button"
          onClick={() => this.handleHideClick()}
          className="btn btn-danger btn-sm position-relative top-50 start-50 translate-middle"
        >
          Cacher
        </button>
      );
    } else {
      button = (
        <button
          type="button"
          onClick={() => this.handleShowClick()}
          className="btn btn-primary btn-sm position-relative top-50 start-50 translate-middle"
        >
          Afficher
        </button>
      );
    }

    return (
      <div>
        <div className="row">
          <div className="col-md-10">
            <h5>Épisodes</h5>
          </div>
          <div className="col-md-2">{button}</div>
        </div>
        <br />
        {this.state.show ? (
          <table className="table table-hover border">
            <thead>
              <tr>
                <th scope="col">Saison</th>
                <th scope="col">Numéro épisode</th>
                <th scope="col">Titre</th>
                <th scope="col">Date de sortie</th>
              </tr>
            </thead>
            <tbody>
              {this.props.episodes.map((gh, i) => (
                <tr key={i}>
                  <td>{gh.saison}</td>
                  <td>{gh.num_episode}</td>
                  <td>{gh.titre}</td>
                  <td>{gh.sortie}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <br />
        )}
      </div>
    );
  }
}

export default Episode;
