import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Casting from "./Casting";
import Episode from "./Episode";
import Search from "./Search";
import Seen from "./Seen";
import TechnicalError from "./TechnicalError";
import Spinner from "./Spinner";
import Accordian from "./Accordian";

class Film extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filmData: null,
      technicalError: false,
      error: null,
    };
  }

  componentDidMount() {
    this.getFilm();
  }

  async getFilm() {
    const {
      match: { params },
    } = this.props;

    fetch(process.env.REACT_APP_API_URL + "api/films/" + params.filmID, {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            filmData: result.data,
          });
        },
        (error) => {
          this.setState({
            technicalError: true,
            error: error,
          });
        }
      );
  }

  render() {
    if (this.state.technicalError) {
      return <TechnicalError />;
    }

    if (!sessionStorage.getItem("userToken")) {
      return <Redirect to="/" />;
    }

    if (!this.state.filmData) {
      return <Spinner />;
    }

    let filmData = this.state.filmData;
    const parsedNote = filmData.note;

    return (
      <div>
        <Search type="film" />
        <div className="card mb-3 desc-bloc">
          <div className="row no-gutters">
            <div className="col-md-3">
              <img
                src={
                  process.env.REACT_APP_API_URL +
                  "image-film/" +
                  filmData.idimdb
                }
                className="img-mymdb position-relative top-50 start-50 translate-middle img-fluid shadow-lg rounded "
                alt="..."
              />
            </div>
            <div className="col-md-9">
              <div className="card-body">
                <div className="row">
                  <div className="col-10">
                    <h5 className="card-title">{filmData.titre}</h5>
                  </div>
                  <div className="col-2">
                    <p className="card-text text-end">
                      <a
                        href={"https://www.imdb.com/title/" + filmData.idimdb}
                        target="_blank"
                        without="true"
                        rel="noopener noreferrer"
                      >
                        {filmData.idimdb}
                      </a>
                    </p>
                  </div>
                </div>
                {filmData.directors[0] ? (
                  <p className="card-text">
                    Réalisé par{" "}
                    <a href={"/acteur/" + filmData.directors[0].actor_id}>
                      {filmData.directors[0].nom}
                    </a>
                  </p>
                ) : (
                  <p className="card-text text-danger"><i class="fas fa-exclamation-triangle"> Réalisateur inconnu</i></p>
                )}
                <div className="row">
                  <div className="col-7">
                    <p className="card-text">Film de {filmData.annee}</p>
                  </div>
                  <div className="col-5">
                    <p className="card-text text-end">Genre : {filmData.genre}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12">

                    <div class="progress" role="progressbar" aria-label="Example with label" aria-valuenow={parsedNote} aria-valuemin="0" aria-valuemax="100">
                      <div class="progress-bar" style={{width: parsedNote + '%'}}>{parsedNote}%</div>
                    </div>

                  </div>
                  
                </div>

                <div className="row">
                    <p className="col-12">
                      Durée : {filmData.duree} min
                    </p>
                  </div>

                <p className="card-text text-justify">{filmData.description}</p>
                <p className="card-text text-justify">
                  Source &nbsp;
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/img/logo/" +
                      filmData.source +
                      ".png"
                    }
                    alt="source-img"
                    id="source-img"
                    className="col-md-6 img-fluid source-img"
                  />
                </p>
              </div>
            </div>
            <Seen
              userActions={sessionStorage.getItem("userAction")}
              filmId={this.state.filmData.id}
            />
          </div>
        </div>
        
        <Accordian idimdb={filmData.idimdb} titre={filmData.titre} />
        <div>
          <Episode episodes={this.state.filmData.episodes} />
        </div>
        <br />
        <div>
          <Casting roles={this.state.filmData.roles} />
        </div>
      </div>
    );
  }
}

export default Film;
